<template>
  <div id="subjects" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="students" :paginated="isPaginated" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ students.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="Reg ID" v-slot="props">{{
          props.row.regId
        }}</b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Schoo Class" v-slot="props">{{
          props.row.schoolClass.name
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            :show-update="false"
            :show-remove="false"
          >
            <a
              class="is-small has-text-success"
              @click="initializeAteResultInfo(props.row)"
              style="font-size: 1em; margin-left: 16px"
            >
              <fa-icon icon="file-alt" size="lg" /> </a
          ></action-buttons>
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'ParentsStudents',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Students',
      parent: [],
      students: [],
      isPaginated: false,
      id: null,
    }
  },
  watch: {
    parent(data) {
      this.students = data.students
    },
  },
  methods: {
    initializeInfo(student) {
      this.$router.push(
        `/school/${this.schoolId}/school_class/${student.schoolClass.id}/student_info/${student.id}`
      )
    },
    initializeAteResultInfo(student) {
      this.$router.push(
        `/school/${this.schoolId}/school_class/${student.schoolClass.id}/result_info/${student.id}/term_id/${student.termId}`
      )
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Dashboard',
        route: `/school/${this.schoolId}/dashboard`,
      },
    ])

    this.$apollo.addSmartQuery('parent', {
      query: gql`
        query ParentQuery($id: ID!) {
          parent(id: $id) {
            id
            students {
              id
              name
              regId
              schoolClass {
                id
                name
              }
              age
              gender
              ateMarksAverageScore
              termId
            }
          }
        }
      `,
      variables() {
        return { id: parseInt(this.$route.params.id) }
      },
    })

    this.$apollo.queries.parent.refetch()
  },
}
</script>
